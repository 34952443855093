import Home from "@/pages/Home.vue";
import ComingSoon from "@/pages/ComingSoon.vue";
import NotFound from "@/pages/NotFound.vue";
import { PATHS_EN, ROUTES } from "@/router/constants";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import routerHelper from "@/helpers/router";

const routerRoutes: Array<RouteRecordRaw> = [
  {
    path: PATHS_EN[ROUTES.home],
    name: ROUTES.home,
    component: Home,
  },
  {
    path: PATHS_EN[ROUTES.hideAndSeek],
    name: ROUTES.hideAndSeek,
    component: () => import("../pages/hideAndSeek/HideAndSeek.vue"),
    meta: {
      preserveQuery: ["token"],
      preserveParams: ["collection"],
    },
  },
  {
    path: PATHS_EN[ROUTES.tokenFlip],
    name: ROUTES.tokenFlip,
    component: () => import("../pages/tokenFlip/TokenFlip.vue"),
    meta: {
      preserveQuery: ["token"],
    },
  },
  {
    path: PATHS_EN[ROUTES.rps],
    name: ROUTES.rps,
    component: () => import("../pages/rps/Rps.vue"),
    meta: {
      preserveQuery: ["token"],
      preserveParams: ["collection"],
    },
  },
  {
    path: PATHS_EN[ROUTES.nutsRoulette],
    name: ROUTES.nutsRoulette,
    component: () => import("../pages/nutsRoulette/NutsRoulette.vue"),
    meta: {
      noMargin: true,
    },
  },

  // One Vs One
  {
    path: PATHS_EN[ROUTES.play1v1],
    name: ROUTES.play1v1,
    component: () => import("../pages/oneVsOne/OneVsOne.vue"),
    meta: {
      preserveParams: ["collection"],
    },
  },
  {
    path: PATHS_EN[ROUTES.play1v1Create],
    name: ROUTES.play1v1Create,
    components: {
      default: () => import("../pages/oneVsOne/OneVsOne.vue"),
      modal: () => import("../pages/oneVsOne/Create.vue"),
    },
    beforeEnter: (to, from, next) => routerHelper.isLoggedIn(to, from, next, true, "../pages/oneVsOne/OneVsOne.vue"),
  },

  {
    path: PATHS_EN[ROUTES.roulette],
    name: ROUTES.roulette,
    component: ComingSoon,
  },
  {
    path: PATHS_EN[ROUTES.stats],
    name: ROUTES.stats,
    component: ComingSoon,
  },
  {
    path: PATHS_EN[ROUTES.leaderboard],
    name: ROUTES.leaderboard,
    component: () => import("../pages/leaderboard/Leaderboard.vue"),
  },

  {
    path: PATHS_EN[ROUTES.referrals],
    name: ROUTES.referrals,
    component: () => import("../pages/referrals/Referrals.vue"),
    beforeEnter: (to, from, next) => routerHelper.isLoggedIn(to, from, next),
  },
  {
    path: PATHS_EN[ROUTES.raffle],
    name: ROUTES.raffle,
    component: () => import("../pages/raffle/Raffle.vue"),
  },
  {
    path: PATHS_EN[ROUTES.myNfts],
    name: ROUTES.myNfts,
    component: () => import("../pages/user/NFTs.vue"),
    beforeEnter: (to, from, next) => routerHelper.isLoggedIn(to, from, next),
  },

  // Blackjack
  {
    path: PATHS_EN[ROUTES.blackjack],
    name: ROUTES.blackjack,
    component: () => import("../pages/blackjack/Blackjack.vue"),
    meta: {
      noMargin: true,
      preserveQuery: ["token"],
    },
  },

  // Staking NUTS
  {
    path: PATHS_EN[ROUTES.staking.nuts],
    name: ROUTES.staking.nuts,
    component: () => import("../pages/staking/NutsStaking.vue"),
  },

  // Staking NFTs
  {
    path: PATHS_EN[ROUTES.staking.nfts],
    name: ROUTES.staking.nfts,
    component: () => import("../pages/staking/NftStaking.vue"),
  },

  // Security
  {
    path: PATHS_EN[ROUTES.login],
    name: ROUTES.login,
    components: {
      modal: () => import(/* webpackChunkName: "login" */ "../pages/security/Login.vue"),
    },
    beforeEnter: (to, from, next) => routerHelper.isAnonymous(to, from, next, true),
  },

  // 404 Not Found page
  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routerRoutes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: 0 });
      }, 500);
    });
  },
});

const preserveQuery = (to, from) => {
  const preserveQuery = to.meta.preserveQuery as string[];

  const query = from.query;
  const newQueryParams = Object.keys(query)
    .filter((key) => preserveQuery.includes(key))
    .reduce((obj, key) => {
      obj[key] = query[key];
      return obj;
    }, {});
  const newQuery = { ...to.query, ...newQueryParams };

  // Don't change query parameters if newQuery is empty or route is the same
  if (!Object.keys(newQuery).length || to.name === from.name) {
    return true;
  }

  // Change query parameters only if they are different
  for (const key of Object.keys(newQuery)) {
    if (!to.query?.[key] || newQuery[key] !== to.query[key]) {
      return { ...to, query: newQuery };
    }
  }

  return true;
};

const preserveParams = (to, from) => {
  const preserveParams = to.meta.preserveParams as string[];

  const params = from.params;
  const newParamsTmp = Object.keys(params)
    .filter((key) => preserveParams.includes(key))
    .reduce((obj, key) => {
      obj[key] = params[key];
      return obj;
    }, {});
  const newParams = { ...to.params, ...newParamsTmp };

  // Don't change params if newParams is empty or route is the same
  if (!Object.keys(newParams).length || to.name === from.name) {
    return true;
  }

  // Change params only if they are different
  for (const key of Object.keys(newParams)) {
    if ((!to.params?.[key] && newParams[key]) || newParams[key] !== to.params[key]) {
      return { ...to, params: newParams };
    }
  }

  return true;
};

router.beforeEach((to, from) => {
  let result = null;

  if (to.meta?.preserveQuery) {
    const tmpResult = preserveQuery(to, from);
    if (true !== tmpResult) {
      result = tmpResult;
    }
  }

  if (to.meta?.preserveParams) {
    const tmpResult = preserveParams(result || to, from);
    if (true !== tmpResult) {
      result = tmpResult;
    }
  }

  if (!result) {
    return true;
  }

  return result;
});

export default router;
