export const ROUTES = {
  home: 'home',
  hideAndSeek: 'hideAndSeek',
  tokenFlip: 'tokenFlip',
  rps: 'rps',
  play1v1: 'play1v1',
  play1v1Create: 'play1v1Create',
  blackjack: 'blackjack',
  roulette: 'roulette',
  stats: 'stats',
  leaderboard: 'leaderboard',
  referrals: 'referrals',
  myNfts: 'myNfts',
  login: 'login',
  raffle: 'raffle',
  staking: {
    nuts: 'stakingNuts',
    nfts: 'stakingNfts'
  },
  nutsRoulette: 'nutsRoulette',
};

export const PATHS_EN = {
  [ROUTES.home]: '/',

  [ROUTES.hideAndSeek]: '/card-flip/:collection?',
  [ROUTES.tokenFlip]: '/token-flip/:collection?',
  [ROUTES.rps]: '/rps/:collection?',
  [ROUTES.play1v1]: '/battle-arena/:collection?',
  [ROUTES.play1v1Create]: '/battle-arena/create/:collection?',
  [ROUTES.blackjack]: '/blackjack',
  [ROUTES.roulette]: '/roulette',
  [ROUTES.stats]: '/stats',
  [ROUTES.leaderboard]: '/leaderboard',
  [ROUTES.referrals]: '/referrals',
  [ROUTES.raffle]: '/raffle',
  [ROUTES.myNfts]: '/my-nfts',

  [ROUTES.staking.nuts]: '/staking/nuts',
  [ROUTES.staking.nfts]: '/staking/nfts/:collection?',

  [ROUTES.login]: '/connect-wallet',

  [ROUTES.nutsRoulette]: '/nuts-roulette',
};
