import api, { API_PATHS } from "@/api/index";
import { NftUpgradeInfoModel } from "@/api/models/nftUpgradeInfo.model";

export class GeneralClient {
  async nftUpgradeInfo(): Promise<NftUpgradeInfoModel> {
    try {
      const response = await api.get(API_PATHS.NFT_UPGRADE_INFO);

      return response.data;
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  async getLeaderboard(timeFrame: "all-time" | "weekly" | "previous-week"): Promise<
    {
      address: string;
      egldVolume: number;
    }[]
  > {
    try {
      const response = await api.get(API_PATHS.LEADERBOARD, { timeFrame });

      return response.data;
    } catch (e) {
      console.error(e);

      return [];
    }
  }
}

const generalClient = new GeneralClient();

export default generalClient;
